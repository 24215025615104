import React, { useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import Styles from "./LorryTypeFilter.module.scss";
// Components
const ModalOrBottomSheetWrapper = dynamic(() => import("@vahak/core-ui/dist/components/ModalOrBottomSheetWrapper"));
const InfiniteScroll = dynamic(() => import("react-infinite-scroll-component"));
import Chip from "@vahak/core-ui/dist/components/Chip";
import AppImg from "../../../utils/AppImg";
import Typography from "@vahak/core-ui/dist/components/Typography";
import Dropdown from "@vahak/core/dist/components/dropdown/Dropdown";
import Button from "@vahak/core-ui/dist/components/Button";
import { toast } from "@vahak/core/dist/components/toast/toast";
import { AttachLorryCard, AttachLorryCardShimmer, getEditLorryData } from "../../../attach-lorry/AtttachLorry";
// Constants
import {
    API_REQUEST_TYPE,
    GA4EventNames,
    getLorryDetailsByType,
    LORRY_STATUS,
    NEW_LORRY_TYPES
} from "@vahak/core/dist/constants";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import { IMarketplaceConfig } from "../../../../MarketplaceContextProvider";
import { LoadAggregationResponse } from "../../../../services/load/useGetLoadMarketV2/type";
import { POSTING_FORM_MODAL_ORIGINATION_PAGE, useLoadLorryPosting } from "../../../../context/load-lorry-posting";
import { LORRY_CREATION_FORM_IDS, lorryCreationFormFieldNames } from "../../../posting/helpers/constants";
import { lorryFormValidations } from "../../../posting/helpers/validations/lorryFormValidations";
import { LORRY_POSTING_FORM_LOCATION_STATE } from "../../../posting/lorry-posting/helpers/constants";
import { LorryListResponseData } from "../../../../services/lorry/commonType/type";

// Methods
import { formatLorryNum } from "@vahak/core/dist/methods/formatLorryNum";
import { toggleArrayValue } from "@vahak/core-ui/dist/methods/array";
import { generataMaskUrlWithParams } from "../../../../methods/generataMaskUrlWithParams";

// Hooks
import { useToggleModalWithLocalState } from "@vahak/core-ui/dist/hooks";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import useValidateUserStatus from "../../../../hooks/useValidateUserStatus";
import { useRouter } from "next/router";
import { useGetAllLorriesInfiniteListV2 } from "../../../../services/lorry/useGetAllLorryDetailsV2/useGetAllLorryDetailsV2";

// Assets
import IconWrapper from "@vahak/core-ui/dist/components/IconWrapper";
import ArrowIcon from "@vahak/core/dist/standard-icons/down_arrow.svg";
import LorryWhite from "@vahak/core/dist/standard-icons/lorry-white.svg";
import PlusIcon from "@vahak/core/dist/standard-icons/plus-circle-white.svg";
import CrossIcon from "@vahak/core/dist/icons/crossIconBold.svg";
import AttachLorryIcon from "@vahak/core/dist/standard-icons/attach-lorry-icon.svg";
import { IEventTrackerProp } from "../../../../types/events";

interface LorryTypeFilterProps extends IEventTrackerProp {
    filters: IMarketplaceConfig | undefined;
    onApplyFilters: (values: IMarketplaceConfig | undefined) => void;
    lorryCount?: LoadAggregationResponse["lorry_type_id"];
    companyId?: number;
    showPostedLorryList?: boolean;
}

const ShimmerList = () => (
    <>
        {Array(5)
            .fill(0)
            .map((_, ix) => (
                <AttachLorryCardShimmer key={ix} />
            ))}
    </>
);

type LorryData = LorryListResponseData["lorry_cards"][number];

const LorryTypeFilter = ({
    filters,
    onApplyFilters,
    lorryCount,
    companyId,
    showPostedLorryList = true,
    trackEvent
}: LorryTypeFilterProps) => {
    const router = useRouter();

    const lorryListRef = useRef<HTMLDivElement>(null);

    const isMobileScreen = useMediaQuery({ queryType: "mobile" });
    const [lorriesDetails, setLorriesDetails] = useState<LorryListResponseData>();
    const [selectedLorry, setSelectedLorry] = useState<{
        id?: number;
        number?: string;
        type?: number;
    }>();

    const { ValidateLorryPosting } = useValidateUserStatus();
    const { updateLoadLorryPostingData, openLorryPostingModal } = useLoadLorryPosting();

    const lorrySelectionPopupState = useToggleModalWithLocalState();

    const handlePostingException = () => {
        setSelectedLorry(undefined);
        onApplyFilters({
            ...filters,
            lorryTypeIds: [],
            selectedLorryId: undefined,
            selectLorryNumber: undefined
        });
    };

    const wrapActionWithLoginModal = (cb: Function) => {
        if (companyId) {
            cb();
        } else {
            router.push(
                {
                    hash: "Login"
                },
                undefined,
                { shallow: true }
            );
        }
    };

    const handleClickAttachLorry = () => {
        lorrySelectionPopupState?.closeModal();
        ValidateLorryPosting?.(() => {
            //Callback function
            openLorryPostingModal(LORRY_CREATION_FORM_IDS.VEHICLE_NUMBER);
            updateLoadLorryPostingData({
                isModalOpen: true,
                type: "lorry",
                category: "post",
                originationPage: POSTING_FORM_MODAL_ORIGINATION_PAGE.MARKETPLACE,
                onSuccess: (data: any) => {
                    const lorryType = data?.data?.type ? Number(data?.data?.type) : undefined;
                    onApplyFilters({
                        ...filters,
                        selectedLorryId: data?.data?.id,
                        selectLorryNumber: data?.data?.number,
                        lorryTypeIds: lorryType ? [lorryType] : []
                    });
                    setSelectedLorry({
                        id: data?.data?.id,
                        number: data?.data?.number,
                        type: lorryType
                    });
                    setTimeout(() => {
                        lorryListResponse?.refetch?.();
                    }, 800);
                },
                onFailure: handlePostingException,
                onClose: handlePostingException,
                skipSuccessScreen: true
            });
        });
    };

    const handleCardClick = async (info: LorryData) => {
        trackEvent?.(GA4EventNames.MARKET_PLACE["select_from_existing_clicked"], {
            from_screen: "marketplace_filter"
        });
        const selectLorryPayload = {
            id: info?.lorry_detail?.id,
            number: info?.lorry_detail?.number,
            type: info?.lorry_detail?.type_value
        };
        lorrySelectionPopupState?.closeModal();
        if (info.lorry_detail?.status === LORRY_STATUS.ACTIVE) {
            setSelectedLorry(selectLorryPayload);
            onApplyFilters({
                ...filters,
                lorryTypeIds: [info?.lorry_detail?.type_value!],
                selectedLorryId: info?.lorry_detail?.id,
                selectLorryNumber: info?.lorry_detail?.number
            });
        } else {
            const { lorry_detail } = info;
            const lorryNumberSchema = lorryFormValidations[LORRY_CREATION_FORM_IDS.VEHICLE_NUMBER];
            const lorryNumberData = {
                [lorryCreationFormFieldNames.lorryNum]: lorry_detail?.number
            };

            if (!(await lorryNumberSchema.isValid(lorryNumberData))) {
                return toast.error("Invalid lorry number");
            }
            // validate and switch b/w edit modal or current location
            const dataToValidate = {
                [lorryCreationFormFieldNames.lorryType]: lorry_detail?.type_value,
                [lorryCreationFormFieldNames.lorryCapacity]: lorry_detail?.capacity,
                [lorryCreationFormFieldNames.lorryTyres]: lorry_detail?.total_tyres,
                [lorryCreationFormFieldNames.lorrySize]: lorry_detail?.size,
                [lorryCreationFormFieldNames.sizeText]: lorry_detail?.size_text,
                [lorryCreationFormFieldNames.lorryBodyType]: lorry_detail?.body_type
            };

            const schema = lorryFormValidations[LORRY_CREATION_FORM_IDS.VEHICLE_TYPE];
            schema
                .validate(dataToValidate)
                .then((valid) => {
                    setSelectedLorry(selectLorryPayload);
                    router.query[LORRY_POSTING_FORM_LOCATION_STATE.PARAM_NAME] =
                        LORRY_CREATION_FORM_IDS.VEHICLE_ROUTES_SELECTION;
                })
                .catch((err) => {
                    router.query[LORRY_POSTING_FORM_LOCATION_STATE.PARAM_NAME] = LORRY_CREATION_FORM_IDS.VEHICLE_TYPE;
                    console.error(`ReactivateLoadLorry:`, err);
                })
                .finally(() => {
                    ValidateLorryPosting?.(() => {
                        setSelectedLorry(selectLorryPayload);
                        updateLoadLorryPostingData({
                            isModalOpen: true,
                            type: "lorry",
                            category: "re-post",
                            ...(info?.lorry_detail?.id && {
                                data: {
                                    id: info.lorry_detail.id,
                                    values: getEditLorryData(info)
                                }
                            }),
                            originationPage: POSTING_FORM_MODAL_ORIGINATION_PAGE.DASHBOARD,
                            onSuccess: (data) => {
                                setTimeout(() => {
                                    lorryListResponse?.refetch();
                                }, 800);
                                onApplyFilters({
                                    ...filters,
                                    selectedLorryId: info?.lorry_detail?.id,
                                    selectLorryNumber: info?.lorry_detail?.number,
                                    lorryTypeIds: info?.lorry_detail?.type_value
                                        ? [info?.lorry_detail?.type_value]
                                        : undefined
                                });
                            },
                            onFailure: handlePostingException,
                            onClose: handlePostingException
                        });

                        router.push(router, generataMaskUrlWithParams(router.query), {
                            shallow: true
                        });
                    });
                });
        }
    };

    const lorryListResponse = useGetAllLorriesInfiniteListV2(
        {
            companyId: companyId!,
            preloads: ["company_detail"],
            limit: 10,
            status: [LORRY_STATUS.ACTIVE, LORRY_STATUS.IN_ACTIVE],
            filter_out_phantom_lorry: true,
            request_type: API_REQUEST_TYPE.ELASTIC_SEARCH
        },
        {
            retry: 0,
            refetchOnWindowFocus: false,
            enabled: !!companyId && lorrySelectionPopupState.isModalOpen,
            onSuccess(data) {
                setLorriesDetails(data?.[0]);
            },
            onError(err) {}
        }
    );

    const isEmptyList = lorryListResponse?.isFetched && !lorriesDetails?.lorry_cards?.length;
    const isFetchingList = !lorryListResponse?.isFetched || lorryListResponse?.isFetching;

    const List = (
        <div className={Styles.dropDownListWrapper}>
            {!isEmptyList && (
                <Button
                    isRounded
                    blockBtn
                    startIcon={<PlusIcon />}
                    onClick={() => {
                        handleClickAttachLorry();
                        trackEvent?.(GA4EventNames.MARKET_PLACE["add_lorry_attach_lorry_btn"], {
                            from_screen: "marketplace_filter_attach_vehicle"
                        });
                    }}
                >
                    Attach new vehicle
                </Button>
            )}
            <div className={Styles.dropDownList} id="scrollableDiv">
                <InfiniteScroll
                    dataLength={lorriesDetails?.lorry_cards?.length ?? 0}
                    next={lorryListResponse?.fetchNextPage}
                    loader={""}
                    hasMore={!!lorryListResponse?.hasNextPage}
                    scrollableTarget="scrollableDiv"
                >
                    {lorriesDetails?.lorry_cards?.map((lorry) => {
                        return (
                            <AttachLorryCard
                                key={lorry?.lorry_detail?.id}
                                lorry={lorry}
                                isSelected={lorry?.lorry_detail?.id === selectedLorry?.id}
                                onSelect={() => {
                                    handleCardClick(lorry);
                                }}
                            />
                        );
                    })}
                    {isFetchingList ? (
                        <ShimmerList />
                    ) : (
                        isEmptyList && (
                            <div className={Styles.noItemList}>
                                <AttachLorryIcon />
                                <Typography weight="semibold" size="md" color={COLORS.GREY_600} align="center">
                                    Add a lorry to find matching loads
                                </Typography>
                                <Button
                                    isRounded
                                    blockBtn
                                    startIcon={<PlusIcon />}
                                    onClick={() => {
                                        handleClickAttachLorry();
                                        trackEvent?.(GA4EventNames.MARKET_PLACE["add_lorry_attach_lorry_btn"], {
                                            from_screen: "marketplace_filter_empty_list"
                                        });
                                    }}
                                >
                                    Attach new vehicle
                                </Button>
                            </div>
                        )
                    )}
                </InfiniteScroll>
            </div>
        </div>
    );

    const LorrySelectionFilterSlot = showPostedLorryList && (
        <Dropdown
            isOpen={lorrySelectionPopupState?.isModalOpen}
            handleToggle={() => {
                trackEvent?.(GA4EventNames.MARKET_PLACE["filters_clicked"]);
                wrapActionWithLoginModal(lorrySelectionPopupState?.toggleModal);
            }}
            dropdownClassName={Styles.dropDownToggler}
            DropdownToggleBtn={
                <Chip radius={12} size="auto" outlined outlineColor={COLORS.GREY_200} filled={false} padded={false}>
                    <div className={Styles.chip}>
                        {!!selectedLorry && (
                            <div
                                className={Styles.remove}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handlePostingException();
                                }}
                            >
                                <CrossIcon />
                            </div>
                        )}
                        <div className={Styles.row}>
                            <IconWrapper svgFillColor={COLORS.GREY_500} width={36} height={36}>
                                <LorryWhite />
                            </IconWrapper>
                            {!selectedLorry && (
                                <IconWrapper width={16} height={16} gap={8} svgFillColor="black">
                                    <ArrowIcon />
                                </IconWrapper>
                            )}
                        </div>
                        <Typography weight="medium">
                            {selectedLorry ? (
                                formatLorryNum(selectedLorry?.number)
                            ) : (
                                <>
                                    My lorry
                                    {`${lorriesDetails?.total_records ? ` (${lorriesDetails?.total_records})` : ""}`}
                                </>
                            )}
                        </Typography>
                    </div>
                </Chip>
            }
        >
            {isMobileScreen && lorrySelectionPopupState?.isModalOpen ? (
                <ModalOrBottomSheetWrapper
                    isOpen={lorrySelectionPopupState?.isModalOpen}
                    titleText="Select Lorry"
                    onToggle={lorrySelectionPopupState?.toggleModal}
                >
                    {List}
                </ModalOrBottomSheetWrapper>
            ) : (
                List
            )}
        </Dropdown>
    );
    const LorryTypeFilterSlot = !filters?.selectedLorryId && (
        <>
            {NEW_LORRY_TYPES.sort((a, b) =>
                filters?.lorryTypeIds?.includes(a?.id) === filters?.lorryTypeIds?.includes(b?.id)
                    ? 0
                    : filters?.lorryTypeIds?.includes(a?.id)
                    ? -1
                    : 1
            ).map((lorry) => {
                const count = lorryCount?.find((l) => l.lorry_type_id === lorry?.id)?.count;
                const isActive = filters?.lorryTypeIds?.includes(lorry?.id);
                return (
                    <Chip
                        key={lorry?.id}
                        radius={12}
                        size="auto"
                        outlined
                        outlineColor={COLORS.GREY_200}
                        filled={false}
                        padded={false}
                        {...(isActive && {
                            filled: true,
                            fillColor: COLORS.BLUE_100,
                            outlineColor: COLORS.BLUE,
                            fontColor: COLORS.BLUE
                        })}
                        blockWidth
                    >
                        <div
                            className={Styles.chip}
                            onClick={() => {
                                const lorryTypeIds = toggleArrayValue(filters?.lorryTypeIds ?? [], lorry?.id);
                                trackEvent?.(GA4EventNames.MARKET_PLACE["filters_clicked"], {
                                    filter_selection: lorryTypeIds?.map((lr) => getLorryDetailsByType(lr)?.label)
                                });

                                onApplyFilters({
                                    ...filters,
                                    lorryTypeIds: lorryTypeIds,
                                    selectedLorryId: undefined,
                                    selectLorryNumber: undefined
                                });
                                setSelectedLorry(undefined);
                                lorryListRef?.current?.scrollTo({ behavior: "smooth", left: 0 });
                            }}
                        >
                            {isActive && (
                                <div className={Styles.remove}>
                                    <CrossIcon />
                                </div>
                            )}

                            <AppImg src={`/${lorry?.fullIconRight}`} width={108} height={44} unoptimized />
                            <Typography>
                                {lorry?.label}
                                {!!count ? `(${count})` : "   "}
                            </Typography>
                        </div>
                    </Chip>
                );
            })}
        </>
    );

    useEffect(() => {
        !filters?.selectedLorryId && setSelectedLorry(undefined);
    }, [filters?.selectedLorryId]);

    return (
        <div className={Styles.main}>
            {isMobileScreen ? (
                <div className={Styles.list} ref={lorryListRef}>
                    {LorrySelectionFilterSlot}
                    {LorryTypeFilterSlot}
                </div>
            ) : (
                <>
                    <Typography size="sm" weight="medium" data-filter-label>
                        Filter by
                    </Typography>
                    {LorrySelectionFilterSlot}
                    <div className={Styles.list} ref={lorryListRef}>
                        {LorryTypeFilterSlot}
                    </div>
                </>
            )}
        </div>
    );
};

export default LorryTypeFilter;
